<template>
  <section id="suscripcion-editar">
    <b-col cols="14">
      <b-card>
        <div>
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <h4 class="my-1">Editar Suscripción</h4>
          </div>
          <!-- <div id="loading-bg" v-if="loading">
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </div> -->

          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- Field: Cliente -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="Cliente" label-for="cliente">
                  <b-form-input
                    id="cliente"
                    disabled
                    v-model="cliente"
                    value="cliente"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Email -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="Email" label-for="email">
                  <b-form-input
                    type="email"
                    id="email"
                    disabled
                    v-model="email"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Teléfono -->
              <b-col cols="12" md="6" lg="6">
                <b-form-group label="Teléfono" label-for="telefono">
                  <b-form-input
                    type="tel"
                    id="telefono"
                    disabled
                    v-model="telefono"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Periodicidad -->

            <div class="p-0 mt-1">
              <div class="row align-items-center">
                <div class="col-12">
                  <label id="label-tarifa"> Periodicidad </label>
                </div>
                <b-col>
                  <b-form-radio-group
                    id="periodicidad"
                    v-model="periodicidad"
                    :options="rolPeriodicidad"
                    disabled
                    value="Semanal"
                    class="custom-control-info"
                  />
                </b-col>
              </div>
            </div>

            <!-- Field: Duración de la suscripción-->

            <div class="p-0 mt-3">
              <div class="row align-items-center">
                <div class="col">
                  <label id="label-tarifa"> Duración de la Suscripción </label>
                </div>
              </div>
            </div>

            <div>
              <span
                >Programa la fecha de inicio y el número de cobros o períodos de
                la suscripción.
              </span>
              <br />
              <span
                >Si selecciona indefinido, se seguirá cobrando cada fecha
                indicada según la periodicidad elegida en el paso
                anterior.</span
              >
              <br />
              <b-row class="my-1">
                <feather-icon size="20" icon="InfoIcon" class="mx-1" />
                <span class="font-weight-bolder"
                  >Los cobros se realizarán con una periodicidad Mensual de
                  manera indefinida a partir de la fecha de inicio de
                  cobro.</span
                >
              </b-row>

              <div>
                <label>Inicio de cobro</label>
                <b-row align-v="center">
                  <b-col cols="12" md="6" lg="6">
                    <b-form-datepicker
                      id="desde-datepicker"
                      v-model="inicioCobro"
                      disabled
                      class="btn-filtro"
                      selected-variant="info"
                      button-variant="btn-info"
                      value="inicioCobro"
                      label-no-date-selected=""
                      nav-button-variant="info"
                      :state="validation"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                    />
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      id="indefinido-checkbox"
                      v-model="indefinido"
                      disabled
                      name="indefinido-checkbox"
                      value="indefinido"
                      unchecked-value="not_indefinido"
                      class="custom-control-info"
                    >
                      Indefinido
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- Field: Reintegro-->
            <div class="p-0 mt-3">
              <div class="row align-items-center">
                <div class="col-12">
                  <label id="label-tarifa"> Reintentos de cobro </label>
                </div>
                <b-col>
                  <b-form-radio-group
                    id="reintegro"
                    disabled
                    v-model="reintegro"
                    :options="rolReintegro"
                    value="1"
                    class="custom-control-info"
                  />
                </b-col>
              </div>
            </div>

            <!-- Field: Monto-->
            <div class="p-0 mt-3">
              <div class="row align-items-center">
                <div class="col">
                  <label id="label-tarifa"> Monto </label>
                </div>
              </div>
            </div>

            <b-row>
              <!-- COLUMNA IZQUIERDA -->
              <b-col>
                <div class="row">
                  <b-col cols="12" md="6" lg="12">
                    <b-form-group label="Subtotal" label-for="subtotal">
                      <b-form-input
                        type="number"
                        id="subtotal"
                        v-model="subtotal"
                      />
                    </b-form-group>
                  </b-col>
                </div>
              </b-col>
              <!-- COLUMNA DERECHA -->
              <b-col>
                <div id="card-monto">
                  <h4 class="mb-2">Resumen de la compra</h4>

                  <hr />
                  <b-row>
                    <b-col>
                      <h4>Total</h4>
                    </b-col>
                    <b-col cols="3" class="text-right">
                      <h4>$ {{ subtotal }}</h4>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-form>

          <!-- botones -->
          <div class="row align-items-center mt-5">
            <div>
              <b-alert
                v-height-fade.appear
                :show="dismissCountDown"
                dismissible
                class="mb-0 ml-1"
                variant="warning"
                id="empty-field-popup"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
              >
                <div class="alert-body">
                  <span class="bold">Advertencia! </span>
                  <span>Debes completar los campos obligatorios </span>
                </div>
              </b-alert>
            </div>
            <div class="col justify-content-end">
              <div style="display: flex">
                <div style="margin-left: auto">
                  <b-button
                    id="cancel-button"
                    variant="outline-primary"
                    class="mr-1"
                    @click="modalCancelarShow = !modalCancelarShow"
                    v-b-modal.modal-Cancelar
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    variant="primary"
                    class="shadow-none"
                    id="button-guardar"
                    @click="validateEmptyFields()"
                    :disabled="$store.state.appConfig.loading"
                  >
                    Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-modal
      id="modal-Cancelar"
      body-bg-variant="light-warning"
      v-model="modalCancelarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-Cancelar"
    >
      <label id="label-confirmacion-cancelar">
        ¿ Seguro que quieres cancelar?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideCancelarModal"
          >
            No
          </b-button>
          <router-link to="/dashboard/suscripciones">
            <b-button
              variant="outline-warning"
              class="modal-button-size shadow-none"
              id="agregar-button-modal"
            >
              Sí
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BTable,
  BFormDatepicker,
  BModal,
  BFormRadio,
  BAlert,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import moment from 'moment';
import Button from '@/views/components/button/Button.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BTable,
    Button,
    BFormDatepicker,
    BModal,
    BFormRadio,
    ToastificationContent,
    BAlert,
    BFormCheckbox,
  },
  created() {
    this.getInfoSuscripcion();
  },
  methods: {
    hideEditarModal() {
      this.$refs['modal-editar'].hide();
    },
    hideAgregarModal() {
      this.$refs['modal-agregar'].hide();
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar-tarifa'].hide();
    },
    hideCancelarModal() {
      this.$refs['modal-Cancelar'].hide();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showSuscripcionModificadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se ha modificado la Suscripción exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showProductoModificadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Modificar la Suscripción',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    async getInfoSuscripcion() {
      let id = this.suscripcionSelected;
      const token = localStorage.getItem('token');

      var config = {
        method: 'get',
        url: `${backendUrl}/operaciones/suscripcion/editSuscripcion/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios(config);
        const suscriptionData = resp.data.data;
        this.suscripcionInfo = suscriptionData[0];
        this.cliente =
          suscriptionData[0].name == null
            ? 'No Identificado'
            : suscriptionData[0].name;
        this.email = suscriptionData[0].email_user_app;
        this.telefono =
          suscriptionData[0].telefono == null
            ? 'Sin Numero'
            : suscriptionData[0].telefono;
        this.periodicidad =
          suscriptionData[0].periodicidad == null
            ? 4
            : suscriptionData[0].periodicidad;
        this.inicioCobro =
          suscriptionData[0].date_create == null
            ? new Date()
            : suscriptionData[0].date_create;
        this.subtotal =
          suscriptionData[0].amount == null ? 0 : suscriptionData[0].amount;
        this.total = this.subtotal;
        this.reintegro = this.reintegro == null ? 1 : 2;
      } catch (error) {
      }
    },
    async validateEmptyFields() {
      if (
        this.cliente != '' &&
        this.email != '' &&
        this.telefono != '' &&
        this.periodicidad != '' &&
        this.inicioCobro != '' &&
        /* indefinido , */
        this.reintegro != ''
      ) {
        this.$store.commit("appConfig/changeLoading",true);
        let id = this.suscripcionSelected;
        const token = localStorage.getItem('token');
        let data = {
          subtot: this.subtotal,
          tot: this.subtotal,
        };
        var config = {
          method: 'post',
          url: `${backendUrl}/operaciones/suscripcion/editSusc/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        try {
          await axios(config);
          const suscripciones = await axios({
            method: 'get',
            url: `${backendUrl}/operaciones/suscripcion/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          this.$store.commit(
            'appConfig/saveSuscripciones',
            suscripciones.data.suscriptions
          );
          this.$store.commit("appConfig/changeLoading",false);
         // this.loading = false;
          this.showSuscripcionModificadoExitosoToast('success');
          this.$router.push('/dashboard/suscripciones');
        } catch (error) {
          this.$store.commit("appConfig/changeLoading",false);
        }
      } else {
        this.$store.commit("appConfig/changeLoading",false);
        //this.loading = false;
        this.showAlert();
      }
    },
  },

  props: { suscripcionSelected: { type: Object, required: true } },

  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      dir: 'ltr',
      fields: [
        { key: 'precio', label: 'PRECIO' },
        { key: 'id', label: 'ID' },
        { key: 'fecha', label: 'FECHA' },
        { key: 'tipo', label: 'TIPO' },
        { key: 'suscripciones', label: 'SUSCRIPCIONES' },
        { key: 'accion', label: 'ACCION' },
      ],
      //DATOS SUSCRIPCIÓN EDITADO
      suscripcionInfo: '',
      cliente: '',
      email: '',
      telefono: '',
      periodicidad: '',
      inicioCobro: '',
      indefinido: false,
      reintegro: '',
      subtotal: '',
      total: '',
    };
  },

  setup() {
    const rolPeriodicidad = [
      { text: 'diario', value: 1 },
      { text: 'Semanal', value: 2 },
      { text: 'Quincenal', value: 3 },
      { text: 'Mensual', value: 4 },
      { text: 'Trimestral', value: 5 },
      { text: 'Semestral', value: 6 },
      { text: 'Anual', value: 7 },
    ];

    const rolReintegro = [
      {
        text: 'Calendarizado',
        value: 1,
      },
      {
        text: 'Fijo',
        value: 2,
      },
    ];

    return {
      rolPeriodicidad,
      rolReintegro,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#selectProductos .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}

.action-button {
  border: none;
  background: transparent;
  width: 18px;
}

#cancel-button {
  width: 146.22px;
}

#button-guardar {
  width: 146.22px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

#label-tarifa {
  font-size: 15px;
  font-weight: bold;
}

.modal-button-size {
  width: 166px;
}

#agregar-button-modal {
  margin-left: 14px;
}

#label-confirmacion {
  margin-left: 12%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

#card-monto {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-left: 28px;
  padding-right: 18px;
  padding-bottom: 8px;
  padding-top: 16px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  /* position: absolute; */
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
